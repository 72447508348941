import * as React from 'react';
import clsx from 'clsx';

import styles from './ProductListView.module.css';

interface Props {
  bannerTitle: string;
  imageUrls: string[];
}

export const KeyVisualView = ({ imageUrls, bannerTitle }: Props) => {
  const [activeIndex, setActiveIndex] = React.useState(0);

  const handlePrev = () =>
    setActiveIndex(activeIndex <= 0 ? imageUrls.length - 1 : activeIndex - 1);

  const handleNext = () =>
    setActiveIndex(activeIndex >= imageUrls.length - 1 ? 0 : activeIndex + 1);

  // Preload images for backgrounds
  React.useEffect(() => {
    (async () => {
      const promises = await imageUrls.map(
        (url) =>
          new Promise((resolve, reject) => {
            const img = new Image();

            img.src = url;
            img.onload = resolve;
            img.onerror = reject;
          })
      );
      await Promise.all(promises);
    })();
  }, [imageUrls]);
  React.useEffect(() => {
    const timer = setTimeout(() => {
      handleNext();
    }, 5 * 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [activeIndex]);

  if (imageUrls.length === 0) {
    return null;
  }

  if (imageUrls.length === 0) {
    return null;
  }

  const bannerImageUrl = imageUrls[activeIndex];

  return (
    <div className={styles['parallax-bg']} style={{ backgroundImage: `url(${bannerImageUrl})` }}>
      <div className={clsx('base-top', styles['top'])}>
        <div className={clsx('base-top__body', styles['top__body'])}>
          <div
            className={clsx(
              'base-top__body__headline',
              styles['pacific-rotate'],
              styles['big-text'],
              styles['txt-center']
            )}
          >
            {bannerTitle}
          </div>
          {/* <p className="base-top__body__since pacific-rotate txt-center">Since 2000</p> */}
        </div>
      </div>

      {imageUrls.length > 1 && (
        <>
          <button
            className={clsx('c-photo__main__prev', styles['prev-next-btn'])}
            onClick={handlePrev}
          />
          <button
            className={clsx('c-photo__main__next', styles['prev-next-btn'])}
            onClick={handleNext}
          />
        </>
      )}
    </div>
  );
};
