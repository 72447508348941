import * as React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { ProductSummaryCard } from 'components/ProductSummaryCard/ProductSummaryCard';
import { TopPageCustomSections } from 'components/TopPageCustomSections/TopPageCustomSections';
import { ProductListViewProps } from 'lib/themes/themes';

import { KeyVisualView } from './KeyVisualView';
import styles from './ProductListView.module.css';

const ProductListView = ({
  bannerImageUrls,
  bannerTitle,
  bannerDescription,
  productSummaries,
}: ProductListViewProps) => {
  const { t } = useTranslation();

  return (
    <>
      <KeyVisualView imageUrls={bannerImageUrls} bannerTitle={bannerTitle} />
      <div className={styles['c-title']}>
        <p className={styles['center-text-box']}>{bannerDescription}</p>
      </div>
      <TopPageCustomSections />
      <article className="base-archive">
        <h3
          className={clsx(
            styles['pacific-rotate'],
            styles['txt-center'],
            styles['semi-big-text'],
            'base-marginBottom-16',
            'base-key__text'
          )}
        >
          {t('Plans')}
        </h3>
        <ul className="base-archive__list">
          {productSummaries.map((productSummary, idx) => (
            <li key={idx} className="base-archive__list__item col-3">
              <ProductSummaryCard product={productSummary} />
            </li>
          ))}
        </ul>
      </article>
    </>
  );
};

export default ProductListView;
